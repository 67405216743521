import { Mutex } from 'async-mutex'
import axios, { AxiosError, AxiosResponse, AxiosStatic } from 'axios'
import { BaseResponse } from 'src/app/models/api.types'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import { API_REPORT_EXHIBITION } from 'src/app/modules/report/services/Report.service'
import { Refs } from 'src/app/utils/refs-utils'

const mutex = new Mutex()
const plainAxios = axios.create()
const methodsWithToast = ['post', 'put', 'delete', 'patch'];
const responseCodeWithNoToast: string[] = [
  "PAKUWON-400", 
  "PAKUWON-LOGOUT-CMS-200",
  "PAKUWON-ROLES-UPDATE-403",
  "PAKUWON-STATIC-GUARD-406",
  "PAKUWON-SET-PASSWORD-200",
  "PAKUWON-REWARDS-REDEEM-406",
  "PAKUWON-CAMPAIGNSMODULE-406",
  "PAKUWON-REWARDS-BUY-403",
  "PAKUWON-REWARDS-REDEEM-403",
  "PAKUWON-MERCHANTS-IMAGE-CREATE-200",
  "PAKUWON-MERCHANTS-CREATE-200",
  "PAKUWON-MERCHANTS-UPDATE-200",
  "PAKUWON-MEMBER-TICKET-404",
  "PAKUWON-REWARDS-BUY-TICKET-400"
]

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL

  localAxios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState()

      if (accessToken && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${accessToken}`
      } else if (!config.headers['app-token']) {
        config.headers['app-token'] = process.env.REACT_APP_API_TOKEN;
      }

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  localAxios.interceptors.response.use(
    (response: AxiosResponse<BaseResponse<any>, any>) => {
      const { data, config } = response ?? {}
      const url = config.url;

      if (
        !response.config.headers['With-No-PageToast'] &&
        !response.config.headers['With-No-PageToast-On-Success'] &&
        !!response.config.headers['Authorization'] &&
        url != API_REPORT_EXHIBITION &&
        methodsWithToast.includes(response.config.method!) &&
        !responseCodeWithNoToast.includes(data.response_schema?.response_code ?? "")
      ) {
        Refs.pageToast.show({
          message: response.data.response_schema?.response_message?.en!,
          severity: 'success'
        });
      }
      return (response);
    },
    async (error: AxiosError<BaseResponse<any>>) => {
      const {
        auth: { refreshToken },
      } = store.getState()
      const { dispatch } = store
      const { data, config } = error.response ?? {}
      const url = config?.url;
      
      if (data?.response_schema?.response_code === "PAKUWON-AUTH-GUARD-401") {
        return mutex.runExclusive(async () => {
          const refresh = await _refreshToken(refreshToken)

          if (refresh && !!error.config) {
            error.config.headers.Authorization = `Bearer ${store.getState().auth.accessToken}`
            try {
              return await plainAxios.request(error.config)
            } catch (err: any) {
              if (err.response?.status === 401) dispatch(AuthRedux.actions.logout())
              throw err
            }
          }
          dispatch(AuthRedux.actions.logout())
          throw error
        })
      } 
      else if (data?.response_schema?.response_code === "PAKUWON-AUTH-GUARD-409") {
        window.location.href = '/logout';
      }
      else if (data?.response_schema?.response_code === "PAKUWON-ACCESS-VALIDATION-403") {
        window.location.href = '/logout';
      }
      // Handle DTO error from backend
      else if (responseCodeWithNoToast.includes(data?.response_schema?.response_code ?? "")) {
        // don't show toast
      }
      else if (
        !!error.response &&
        !error.response.config.headers['With-No-PageToast'] &&
        !error.response.config.headers['With-No-PageToast-On-Error'] &&
        !!error.response.config.headers['Authorization'] &&
        url != API_REPORT_EXHIBITION &&
        methodsWithToast.includes(error.response.config.method!)
      ) {
        Refs.pageToast.show({
          elementId: 'dialog-wrapper-content',
          message: error.response.data.response_schema?.response_message?.en!,
          severity: 'danger',
        });
      }
      throw error
    }
  )
  const _refreshToken = async (refreshToken: any) => {
    const { dispatch } = store
    try {
      const result = await plainAxios.get(`/v1/cms/refresh`, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        
      })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            token: result.data.response_output?.detail.token,
            refreshToken: refreshToken,
          })
        )
      return result.data
    } catch (error) {
      dispatch(AuthRedux.actions.logout())
      throw error
    }
  }
}
import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Stack, Divider, Button } from 'src/app/components';
import { InputTimePicker } from '../InputTimePicker/InputTimePicker.page';
import Tag from './Tag.page';
import { useParams } from 'react-router-dom';
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface InputTimeSlotsProps {
  name?: string;
  required? : boolean
  shouldClear: boolean;
  onClearComplete: () => void;
  disabled: boolean
}


const InputTimeSlots: React.FC<InputTimeSlotsProps> = ({ name = 'times', required ,  shouldClear, onClearComplete, disabled}) => {
  const { control, setValue, formState: { errors } } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name
  });

  const params = useParams<{ id: string; path: string }>();
  const paramId = params.id;


  useEffect(() => {
    if (shouldClear) {
      setValue('start_time', '');
      setValue('end_time', '');
      onClearComplete(); 
    }
  }, [shouldClear, setValue, onClearComplete]);


  useEffect(() => {
    if (paramId !== 'add' && fields.length > 0) {

      setValue('start_time',  '');
      setValue('end_time', '');
    }
  }, [paramId, fields, setValue]);

  // Watch the values of start_time and end_time fields
  const startTime = useWatch({ control, name: 'start_time' });
  const endTime = useWatch({ control, name: 'end_time' });
  
  const isAddButtonDisabled = !startTime || !endTime  || disabled;

  const handleAddTime = () => {
    if (startTime && endTime) {
      
      const id = Date.now().toString();

      if(paramId !== 'add' && fields.length > 0){
        update(0, { ...fields[0], start_time: startTime, end_time: endTime });
      }else{
        append({ id, start_time: startTime, end_time: endTime });

      }

      setValue('start_time', '');
      setValue('end_time', '');
    }
  };


  useEffect(() => {
    if (fields.length === 0) {
      setValue('time_ranges_valid', false);
    } else {
      setValue('time_ranges_valid', true);
    }
  }, [fields, setValue]);

  const findValue = (id: string): any => {
    return fields.find((field) => field.id === id);
  };

  return (
    <div className="col-span-2 border border-solid rounded-lg p-4">
      <Stack direction="row" items="center" className="mb-4 gap-4">
        {/* Start Time Picker */}
        <InputTimePicker
          name="start_time"
          placeholder="Select start time"
          disabled={disabled}
          defaultValue={''}
          rule={{
            required: required
          }}
         
         
        />

        {/* Divider */}
        <Divider className="w-[24px] border-t-2" />

        {/* End Time Picker */}
        <InputTimePicker
          name="end_time"
          placeholder="Select end time"
          defaultValue={''}
          disabled={disabled}
          rule={{
            required: required
          }}
         
        />

        {/* Add Time Button */}
        <Button
          variant="outlined"
          onClick={handleAddTime}
          disabled={isAddButtonDisabled || disabled}
          className="ml-2"
          
        >
          Add time +
        </Button>
        
        
      </Stack>

      {/* Display Added Time Slots */}
      <div className="flex flex-wrap gap-2 mt-4">
        {fields.map((field, index) => (
          <Tag
            key={field.id}
            label={`${findValue(field.id)?.start_time} - ${findValue(field.id)?.end_time}`}
            onDelete={() => remove(index)}
            disable={disabled}
          />
        ))}

      
      
      </div>
    </div>
  );
};

export default InputTimeSlots;

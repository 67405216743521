import axios from "axios";

import {
  BaseResponse,
  Pagination,
  QueryModel
} from "src/app/models/api.types";
import { ReportCustomerProfilesModel } from "../page/Report/ReportCustomerProfiles/ReportCustomerProfiles.types";
import { ReportOutletSalesSummaryModel } from "../page/Report/ReportOutletSalesSummary/ReportOutletSalesSummary.types";
import { ReportPromoModel } from "../page/Report/ReportPromo/ReportPromo.types";
import { ReportRewardsModel } from "../page/Report/ReportRewards/ReportRewards.types";
import { ReportWifiModel } from "../page/Report/ReportWifi/ReportWifi.types";

export const URL_REPORTS = '/v1/cms/reports';
export const URL_REPORT_CUSTOMER_PROFILES = `${URL_REPORTS}/customer-profiles`;
export const URL_REPORT_OUTLET_SALES_SUMMARY = `${URL_REPORTS}/outlet-sales-summary`;
export const URL_REPORT_PROMOS = `${URL_REPORTS}/promos`;
export const URL_REPORT_REWARDS = `${URL_REPORTS}/rewards`;
export const URL_REPORT_TRANSACTIONS = `${URL_REPORTS}/transactions`;
export const URL_REPORT_WIFI = `${URL_REPORTS}/wifi`;
export const URL_REPORT_DOWNLOAD = `${URL_REPORTS}/download`;
export const API_REPORT_EXHIBITION = "/v1/cms/reports/download/exhibition";

// get customer profiles
export async function getReportCustomerProfiles(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<ReportCustomerProfilesModel[]>>>(URL_REPORT_CUSTOMER_PROFILES, { params, signal });
}

// get outlet sales summary
export async function getReportOutletSalesSummary(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<ReportOutletSalesSummaryModel[]>>>(URL_REPORT_OUTLET_SALES_SUMMARY, { params, signal });
}

// get promos
export async function getPromos(params: QueryModel, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<ReportPromoModel[]>>>(URL_REPORT_PROMOS, { params, signal });
}

// get rewards
export async function getRewards(params: QueryModel, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<ReportRewardsModel[]>>>(URL_REPORT_REWARDS, { params, signal });
}

// get wifi
export async function getWifi(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<ReportWifiModel[]>>>(URL_REPORT_WIFI, { params, signal });
}



export type DownloadReportResponse = {
  blob: Blob;
  fileName: string;
};

/**
 * Downloads the exhibition report.
 * @param payload The request payload for the download.
 * @returns A Promise resolving to the Blob containing the .xlsx file.
 */
export const downloadExhibitionReport = async (payload: any): Promise<DownloadReportResponse> => {
  try {
    const response = await axios.post(API_REPORT_EXHIBITION, payload, {
      responseType: "blob",
    });

    const contentDisposition = response.headers["content-disposition"];
    const fileName = contentDisposition
      ? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "") // Remove quotes
      : "default_filename.xls";

    return { blob: response.data, fileName };
  } catch (error) {
    console.error("Error downloading report:", error);
    throw error;
  }
};



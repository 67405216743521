import { SVGProps } from "react";

export function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.9984 37.6001C29.7197 37.6001 37.5984 29.7214 37.5984 20.0001C37.5984 10.2789 29.7197 2.40015 19.9984 2.40015C10.2772 2.40015 2.39844 10.2789 2.39844 20.0001C2.39844 29.7214 10.2772 37.6001 19.9984 37.6001Z" fill="#FF5454"/>
    <path d="M26.2078 15.6544C26.7232 15.1391 26.7232 14.3021 26.2078 13.7867C25.6924 13.2713 24.8554 13.2713 24.34 13.7867L19.9984 18.1324L15.6527 13.7908C15.1373 13.2754 14.3004 13.2754 13.785 13.7908C13.2696 14.3062 13.2696 15.1432 13.785 15.6586L18.1307 20.0001L13.7891 24.3459C13.2737 24.8612 13.2737 25.6982 13.7891 26.2136C14.3045 26.729 15.1415 26.729 15.6568 26.2136L19.9984 21.8679L24.3442 26.2095C24.8595 26.7249 25.6965 26.7249 26.2119 26.2095C26.7273 25.6941 26.7273 24.8571 26.2119 24.3417L21.8662 20.0001L26.2078 15.6544Z" fill="white"/>
    </svg>
    
  );
}
